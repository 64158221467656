.scroller::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin: 10px;
}
.scroller::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #3f3c4daa;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}

.hide-scroller::-webkit-scrollbar {
    height: 2px;
    width: 2px;
}

.scrller-container {
    overflow-y: auto;
    max-height: 100vh;
    padding-top: 30px;
}

.horizontal-scrller-container {
    overflow-x: auto;
    max-width: 100vh;
    padding: 10px;
    padding-top: 30px;
}

.horizontal-scroller{
    gap: 10px;
    padding: 10px ;
    display: flex;
    overflow-x: auto;
    flex-direction: row;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.popup {
    /* margin-inline: 15px; */
    display:  flex;
    overflow:auto;
    height: 95vh;
    border-radius: 15px;
    flex-direction: column;
    padding-bottom: 130px;
}

.popup-card {
    padding: 10px;
    /* margin-inline: 10px; */
    font-family: "'GE Dinkum Medium'";
    color: #565077;
    background-color: #FBFBFB;
    border-radius: 13px;
    box-shadow: 0px 0px 10px rgb(45, 48, 62,0.15);
    flex-grow: 1;
}
.dark .popup-card {color: #a4a8b4;background-color: #353848;}
.shake .popup-card {
    /* background-color: #df3743; */
    box-shadow :0px 0px 20px #dd3643;


}

/* fixed items-center inset-0 z-50 backdrop-blur-lg blur-card-1 justify-center */
.popup-2{
    position: fixed;
    inset: 0;
    z-index: 333;
    justify-content: center;
    backdrop-filter: blur(20px);
}

.Grid {
    display: grid;
    grid-gap: 10px;
    padding: 15px;
    align-items: center;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
}



.larg-grid {
    display: grid;
    grid-gap: 10px;
    padding-inline: 10px;
    grid-template-columns: repeat(1, minmax(auto, 1fr));
}


.small-grid {
    display: grid;
    grid-gap: 10px;
    padding-inline: 10px;
    grid-template-columns: repeat(4, minmax(auto, 1fr));
}

.bird {
    /* margin: 10px; */
    position: fixed;
    align-self: flex-start;
    top: 0;
    left: 0;
    right: 0;

    /* border-radius: 10px; */
    padding: 20px 0;
    /* max-width: 300px; */
    /* flex-grow: 1; */
    backdrop-filter: blur(20px);
}


@keyframes blur-frame {
    0% {
        backdrop-filter: blur(0px);
    }
    100% {
        backdrop-filter: blur(10px);
    }
}
 
.bird-in {
    animation: bird-in-frame 0.2s ease-out;
}

.bird-out {
    animation: bird-out-frame 0.2s ease-in;
    transform:
     /*  */
    translateX(300px);
}

@keyframes bird-in-frame {
    0% {
        transform:
         /*  */
        translateX(300px);
    }
    100% {
        transform:
         /*  */
        translateX(0);
    }
}

@keyframes bird-out-frame {
    0% {
        transform:
         /*  */
        translateX(0);
    }
    100% {
        transform:
         /*  */
        translateX(300px);
    }
}

.shake {
    animation: shake-frame 0.3s linear;
    /* transform: rotate(30deg); */
}
@keyframes shake-frame {
   0% {
       transform:
        /* rotate(-3deg)  */
       translateX(10);
   }
   25% {
       transform:
         /* rotate(3deg)  */
       translateX(-5px);
   }
   50% {
       transform:
        /* rotate(-3deg)  */
       translateX(6px);
   }
   75% {
       transform:
        /* rotate(3deg)  */
       translateX(-10px);
   }
   100% {
       transform:
        /* rotate(-0deg)  */
       translateX(0);
   }
}

@media screen and (min-width: 720px) {
    .Grid {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        grid-gap: 10px;
    }
    .popup {
        padding:35px;
    }
}

@media screen and (min-width: 1000px) {
    .Grid {
        grid-template-columns: repeat(3, minmax(auto, 1fr));
        grid-gap: 15px;
    }
}

@media screen and (min-width: 1200px) {
    .larg-grid {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        grid-gap: 45px;
    }

    .small-grid {
        grid-template-columns: repeat(6, minmax(auto, 1fr));
    }
}


/* @media screen and (min-width: 1200px) {
   
} */