  .lds-ripple {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      min-height: 50vh;
  }

  .lds-ripple div {
      opacity: 1;
      border-radius: 50%;
      position: absolute;
      border: 4px solid #22212e;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

   .dark .lds-ripple div {
      border: 4px solid #fff;
   }

  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0.5;
    }
    4.9% {
      width: 0;
      height: 0;
      opacity: 1;
    }
 
    100% {
      width: 102px;
      height: 102px;
      opacity: 0.5;
    }
  }
  

  .embla-Button {
    cursor: pointer;
    touch-action: manipulation;
    position: absolute;
    z-index: 20;
    top: 50%;
    border: unset;
    width: 60px;
    height: 60px;
    padding: 18px;
    /* background-color: rgba(0, 0, 0, 0.1); */
    background-color: #fff;
    border-radius: 45px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    fill: #3f3c4d;
}

.embla-Right {
    opacity: 0.7;
    animation: embla_ToRightFrame 0.3s ease-out;
    transform: rotate(180deg);
}
.embla-Right:hover {
    animation: embla_ToLeftFrame 0.3s ease-out;
    transform: translateX(5px) rotate(180deg);
    opacity: 1;
}

@keyframes embla_ToRightFrame {
    from {
        transform: translateX(5px) rotate(180deg);
    }
    to {
        transform: translateX(0) rotate(180deg);
    }
}
@keyframes embla_ToLeftFrame {
    from {
        transform: translateX(0) rotate(180deg);
    }
    to {
        transform: translateX(5px) rotate(180deg);
    }
}
.embla-Left {
    opacity: 0.7;
    animation: emblaLeft-ToRightFrame 0.3s ease-out;
}
.embla-Left:hover {
    animation: emblaLeft-ToLeftFrame 0.3s ease-out;
    transform: translateX(-5px);
    opacity: 1;
}

@keyframes emblaLeft-ToLeftFrame {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-5px);
    }
}
@keyframes emblaLeft-ToRightFrame {
    from {
        transform: translateX(-5px);
    }
    to {
        transform: translateX(0);
    }
}

.embla-ButtonSvg {
    width: 100%;
    height: 100%;
    /* fill: #d90429; */
    opacity: 0.7;
}

/* @media screen and (max-width: 600px) {
    .embla__button--prev {
        left: 15px;
    }

    .embla__button--next {
        right: 15px;
    }
} */


.translateRight {
  animation: translateRight 0.3s ease-out;
}

@keyframes translateRight {
  from {
      transform: translateX(50px);
      opacity: 0.5;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

.translateLeft {
  animation: translateLeft 0.3s ease-out;
}

@keyframes translateLeft {
  from {
      transform: translateX(-50px);
      opacity: 0.5;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

.translateRight-Obstive {
  animation: translateRightOps 0.3s ease-out;
  transform: translateX(-50px);
  opacity: 0.5;
}

@keyframes translateRightOps {
  from {
      transform: translateX(0);
      opacity: 1;
  }
  to {
      transform: translateX(-50px);
      opacity: 0.5;
  }
}

.translateLeft-Obstive {
  animation: translateLeftOps 0.3s ease-out;
  transform: translateX(50px);
  opacity: 0.5;
}

@keyframes translateLeftOps {
  from {
      transform: translateX(0);
      opacity: 1;
  }
  to {
      transform: translateX(50px);
      opacity: 0.5;
  }
}
