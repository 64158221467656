.checked-box{
    margin: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checked-box .check-bg{
    padding: 2px;
    display: flex;
    margin: 0 4px;
    min-width: 48px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
}
.checked-box .check-bg span{
   padding: 12px;
   border-radius: 24px;
   background-color: #ffffff;
}

.checked .check-bg{
    padding-right: 22px;
    background-color: #dd3643;
}

.product-card {
    background: linear-gradient(230deg,#63cfc9, #0000 50%);
}
.dark .product-card {
    background: linear-gradient(230deg,#353848, #63cfc9);
}


.carbonic-card{background-color: #FFFFFF;} 
.dark .carbonic-card{background-color: #2d303e;} 