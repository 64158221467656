@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --main-bg-color: #22212e;
}
body {
  margin-inline: auto;
  overflow: hidden;
  max-width: 1280px;
  user-select: none;
  background-color: var(--main-bg-color);
  margin: 0;
  padding: 0;
  /* background-color: #aa3333; */
}

.tap-highlight{ -webkit-tap-highlight-color: transparent; }

/* .input-text:focus { box-shadow: 0 0 10px #63cfc9; } */

.font-bold{ font-family: "'GE Dinkum Medium'";}
.font-light{ font-family: "'BoutrosMBCDinkum Medium'";}
 .transtion { transition: all 0.5s ease-in}
 .transtion-3 { transition: all 0.3s ease-in}